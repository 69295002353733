body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-bar {
  height: 8px;
  background-color: #eee;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}

progress::-moz-progress-bar {
  background: rgb(110 60 188 / 1);
}

progress::-webkit-progress-value {
  background: rgb(110 60 188 / 1);
}

progress::-webkit-progress-bar {
  background: #eee;
}